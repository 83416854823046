<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="기안작성">
                    <div class="btn-ibbox title-btnbox float-right">
                        <button class="btn-default">양식선택</button>
                        <button class="btn-default ml-10">불러오기</button>
                        <button class="btn-default ml-10">결재선</button>
                    </div>
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <div class="dp-table w-100per">
                                <div class="float-left w-50per pr-25"> 
                                    <table class="table-row">
                                        <tbody>
                                            <tr>
                                                <th width="150">기안자</th>
                                                <td>Min K (김민정)</td>
                                            </tr>
                                            <tr>
                                                <th width="150">소속</th>
                                                <td>솔루션개발팀</td>
                                            </tr>
                                            <tr>
                                                <th width="150">작성일</th>
                                                <td>2019-00-00</td>
                                            </tr>
                                            <tr>
                                                <th width="150">문서번호</th>
                                                <td>20190000ASD</td>
                                            </tr>
                                            <tr>
                                                <th width="150">보안</th>
                                                <td>
                                                    <label><input type="checkbox" id="Private" name="Private" value="Private"><span class="ml-5"> Private</span></label>
                                                    <div class="mt-5 color-1">
                                                        * 급여성, 인사관련사항 결재 시 체크해주세요.<br>
                                                        * Private 체크 시 회계팀 Ahri (이은주) 참조해 주세요.
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="float-right w-50per pl-25">
                                    <table class="table-col">
                                        <colgroup>
                                            <col span="5"><!--결재지정한 수만큼(thead th수)-->
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>담당</th>
                                                <th>Staff</th>
                                                <th>Staff</th>
                                                <th>팀장</th>
                                                <th>관리자</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table-row mt-45">
                                        <tbody>
                                            <tr>
                                                <th width="110" rowspan="4">참조</th>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>01/29</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <table class="table-row mt-45">
                                <tbody>
                                    <tr>
                                        <th width="150">제목</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-row mt-10">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>구분</th>
                                        <td colspan="3">
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 인사이동</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 승진발령</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 조직개편</span></label>
                                        </td>
                                    </tr>
                                    <!-- 에디터양식일때 -->
                                    <tr>
                                        <th>구분</th>
                                        <td colspan="3">
                                            인사이동 (   )  승진발령 (   )  조직개편 (   ) 
                                        </td>
                                    </tr>
                                    <!-- //에디터양식일때 -->
                                    <tr>
                                        <th>소속/직책/성명</th>
                                        <td colspan="3">
                                            <input type="text" class="w-100per" placeholder="인사이동, 승진발령의 경우 작성해주세요.">
                                        </td>
                                    </tr>
                                    <!-- 에디터양식일때 -->
                                    <tr>
                                        <th>소속/직책/성명</th>
                                        <td colspan="3">
                                            <span class="txt-gray-light">인사이동, 승진발령의 경우 작성해주세요.</span>
                                        </td>
                                    </tr>
                                    <!-- //에디터양식일때 -->
                                    <tr>
                                        <th>신규 보직</th>
                                        <td colspan="3">
                                            <input type="text" class="w-100per" placeholder="인사이동, 승진발령의 경우 작성해주세요.">
                                        </td>
                                    </tr>
                                    <!-- 에디터양식일때 -->
                                    <tr>
                                        <th>신규 보직</th>
                                        <td colspan="3">
                                            <span class="txt-gray-light">인사이동, 승진발령의 경우 작성해주세요.</span>
                                        </td>
                                    </tr>
                                    <!-- //에디터양식일때 -->
                                    <tr>
                                        <th>조직개편</th>
                                        <td colspan="3">
                                            <input type="text" class="w-100per" placeholder="조직개편의 경우 작성해주세요.">
                                        </td>
                                    </tr>
                                    <!-- 에디터양식일때 -->
                                    <tr>
                                        <th>조직개편</th>
                                        <td colspan="3">
                                            <span class="txt-gray-light">조직개편의 경우 작성해주세요.</span>
                                        </td>
                                    </tr>
                                    <!-- //에디터양식일때 -->
                                    <tr>
                                        <th>추천사유<br>또는<br>개편내용</th>
                                        <td colspan="3">
                                            <textarea class="w-100per h-100px" placeholder="추천사유는 간략히 작성해 주세요. 조직개편의 경우 해당 칸에 내용을 대략적으로 작성해 주시고, 조직도가 있다면 파일을 첨부해 주세요."></textarea>
                                        </td>
                                    </tr>
                                    <!-- 에디터양식일때 -->
                                    <tr>
                                        <th>추천사유<br>또는<br>개편내용</th>
                                        <td colspan="3">
                                            <span class="txt-gray-light">추천사유는 간략히 작성해 주세요.<br>조직개편의 경우 해당 칸에 내용을 대략적으로 작성해 주시고, 조직도가 있다면 파일을 첨부해 주세요.</span>
                                        </td>
                                    </tr>
                                    <!-- //에디터양식일때 -->
                                    <tr>
                                        <th>발령일자</th>
                                        <td colspan="3">
                                            <textarea class="w-100per h-100px" placeholder="(EX) 2020.06.01"></textarea>
                                        </td>
                                    </tr>
                                    <!-- 에디터양식일때 -->
                                    <tr>
                                        <th>발령일자</th>
                                        <td colspan="3">
                                            <span class="txt-gray-light">(EX) 2020.06.01</span>
                                        </td>
                                    </tr>
                                    <!-- //에디터양식일때 -->
                                </tbody>
                            </table>

                            <div class="mt-10 txt-center">※ 상기와 같이 인사발령 하고자 하오니 재가 바랍니다.</div>

                            <table class="table-row mt-50">
                                <tbody>
                                    <tr>
                                        <th width="150">파일첨부</th>
                                        <td>
                                            <div>
                                                <!-- 파일첨부 전 -->
                                                <input type="file" style="display: none;">
                                                <!-- 파일첨부 후 -->
                                                <span class="btn-view mr-10">고객사일지파일.pdf</span>
                                                <button class="btn-default dp-inblock h-30px">삭제</button>
                                            </div>
                                            <div class="mt-5"><input type="file"></div>
                                            <div class="mt-5"><input type="file"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <router-link to=""><button class="btn-default float-left mt-30">취소</button></router-link>
                            <router-link to=""><button class="btn-default float-right mt-30">등록</button></router-link>
                            <div class="clear"></div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 


export default {
}
</script>

<style lang="scss" scoped>
</style>